import React from 'react';
import '../../App.css';
import Hero from '../Hero';
import Featured from '../Featured';
import Footer from '../Footer';
import MetaTags from 'react-meta-tags'
//import About from "../About";

function HomePage(){
    return(
        <div>
            <MetaTags>
                <title>AniPC - Home</title>
            </MetaTags>
            <Hero />
            <Featured />
            {/*<About />*/}
            <Footer />
        </div>
    )
}

export default HomePage
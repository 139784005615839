import React, {useEffect, useState} from 'react';
import '../App.css';
import './Featured.css';
import ProductCard from './ProductCard';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Link} from "react-router-dom";

function Featured() {
    const [state, setState] = useState({loading: true, products: [], content: null});

    useEffect(() => {
        let products = []

        fetch('/api/featured').then(async res => {
            const json = await res.json()

            // noinspection JSUnresolvedVariable
            if (json.success) {
                // noinspection JSUnresolvedVariable
                products = json.featuredItems
                const rows = [...Array(Math.ceil(products.length / 4))];
                const productRows = rows.map((row, idx) => products.slice(idx * 4, idx * 4 + 4));
                // noinspection DuplicatedCode
                const content = productRows.map((row, idx) => (
                    <div className="row" key={idx}>
                        {row.map(product =>
                            <ProductCard key={product.id} id={product.id} category={product.categoryName}
                                         productType={product.productTypeId}
                                         title={product.name}
                                         imagePath={"../" + product.imageFolderPath + "/1"}
                                         imageExtension={".png"}
                                         new={product.new}
                                         sale={product.sale}
                                         smallprice={product.priceSmall}
                                         largeprice={product.priceLarge}
                                         smallsale={(product.priceSmall * (1 - product.salePercent / 100)).toFixed(2)}
                                         largesale={(product.priceLarge * (1 - product.salePercent / 100)).toFixed(2)}/>
                        )}
                    </div>)
                );

                setState(state => ({
                    ...state,
                    loading: false,
                    products: products,
                    content: content
                }))
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return state.loading ? null : (
        <div className="featured">
            <div className="featured-banner">
                <h1 className="featured-header">Featured Items</h1>
            </div>
            <div className="featured-content container-fluid">
                {state.content}
                <div className="view-more-button">
                    <Link to="/shop">View More Products</Link>
                </div>
            </div>
        </div>
    )
}

export default Featured
import React, {useState} from 'react';
import '../App.css';
import './NotificationBar.css';
import {withRouter} from "react-router-dom";

function NotificationBar(props) {
    const [hidden, setHidden] = useState(false)

    const onCloseButton = () => {
        setHidden(true)
    }

    return(
        (props.visible && !hidden) ? <div className="notification-bar">
            <div className={"alert " + props.type} role="alert">
                <button type="button" className="close" data-dismiss="alert" onClick={onCloseButton}>×</button>
                <strong>{props.title}</strong> {props.message}
            </div>
        </div> : null
    )
}

export default withRouter(NotificationBar)
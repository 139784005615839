import moment from "moment";

export async function getCartDetails() {
    let cart = JSON.parse(localStorage.getItem('cart'))
    let cartTotal = 0.00

    if (cart === null) {
        cart = {
            items: []
        }
    }

    async function fetchAPI() {
        for (let i = 0; i < cart.items.length; i++) {
            let item = cart.items[i]
            let currentItemPrice = 0

            let product = await fetch("/api/item?productId=" + item.id)
            product = await product.json()

            switch (item.size) {
                case "small":
                    cartTotal += parseFloat(product.item.priceSmall) * (product.item.sale ? (1 - product.item.salePercent / 100) : 1)
                    currentItemPrice = parseFloat(product.item.priceSmall) * (product.item.sale ? (1 - product.item.salePercent / 100) : 1)
                    break;
                case "medium":
                    cartTotal += parseFloat(product.item.priceMedium) * (product.item.sale ? (1 - product.item.salePercent / 100) : 1)
                    currentItemPrice = parseFloat(product.item.priceMedium) * (product.item.sale ? (1 - product.item.salePercent / 100) : 1)
                    break;
                case "large":
                    cartTotal += parseFloat(product.item.priceLarge) * (product.item.sale ? (1 - product.item.salePercent / 100) : 1)
                    currentItemPrice = parseFloat(product.item.priceLarge) * (product.item.sale ? (1 - product.item.salePercent / 100) : 1)
                    break;
                default:
                    break;
            }

            currentItemPrice += (item.frontPanel ? 7.00 : 0)
            currentItemPrice += (item.backPanel ? 14.00 : 0)
            cartTotal += (item.frontPanel ? 7.00 : 0)
            cartTotal += (item.backPanel ? 14.00 : 0)

            // Add imagePath to the items in cart.
            cart.items[i].name = product.item.name
            cart.items[i].price = currentItemPrice
            cart.items[i].imagePath = product.item.imageFolderPath
        }
    }

    await fetchAPI()

    return {
        cart: cart,
        cartTotal: cartTotal
    }
}

export async function getItemDetailFromId(productId) {
    const query = await fetch('/api/item/?productId=' + productId)
    const item = await query.json()
    return item.item
}

export function getSizeNameFromProductType(productType, size) {
    if (productType === 2) {
        switch (size) {
            case "small":
                return "3 pack";
            case "medium":
                return "5 pack";
            case "large":
                return "10 pack";
            default:
                return size;
        }
    } else {
        return size.charAt(0).toUpperCase() + size.slice(1);
    }
}

export function getProductPriceFromSize(item) {
    let price = 0.00
    let size = item.size.toLowerCase()

    if (item.productType === 2) {
        switch (item.size) {
            case "3 pack":
                size = "small";
                break;
            case "5 pack":
                size = "medium";
                break;
            case "10 pack":
                size = "large";
                break;
            default:
                break;
        }
    }

    switch (size) {
        case "small":
            price += parseFloat(item.priceSmall)
            break;
        case "medium":
            price += parseFloat(item.priceMedium)
            break;
        case "large":
            price += parseFloat(item.priceLarge)
            break;
        default:
            price += parseFloat(item.priceMedium)
            break;
    }

    if (item.frontPanel) {
        price += 7.00
    }

    if (item.backPanel) {
        price += 14.00
    }

    return price.toFixed(2)
}

// Source: https://dev.to/shaerins/cropping-and-resizing-images-in-react-360a

const createImage = (url) =>
    new Promise((resolve, reject) => {
        const image = new Image()
        image.addEventListener('load', () => resolve(image))
        image.addEventListener('error', error => reject(error))
        image.setAttribute('crossOrigin', 'anonymous')
        image.src = url
    })

export const getCroppedImg = async (imageSrc, crop) => {
    const image = await createImage(imageSrc)
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')

    /* setting canvas width & height allows us to
    resize from the original image resolution */
    canvas.width = 800
    canvas.height = 800

    ctx.drawImage(
        image,
        crop.x,
        crop.y,
        crop.width,
        crop.height,
        0,
        0,
        canvas.width,
        canvas.height
    )

    return new Promise((resolve) => {
        canvas.toBlob((blob) => {
            resolve(blob)
        }, 'image/png')
    })
}

/**
 * Function returning the build date(as per provided epoch)
 * @param epoch Time in milliseconds
 */
export const getBuildDate = (epoch) => {
    return moment(epoch).format("DD-MM-YYY HH:MM");
};
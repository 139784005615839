import React, {useState} from 'react';
import '../App.css';
import './Navbar.css';
import {Link, NavLink, withRouter} from 'react-router-dom';

function Navbar(props) {
    const [click, setClick] = useState(false);

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);

    return (
        <nav className={props.location.pathname === "/" ? "navbar-container" : "navbar-container colored"}>
            <div className="navbar-logo">
                <Link to="/" onClick={closeMobileMenu}>
                    <picture>
                        <source srcSet={"images/logo.webp"}/>
                        <img src={"images/logo.png"} alt="AniPC Logo" width="220px" height="100%"/>
                    </picture>
                </Link>
            </div>
            <div>
                <div className='menu-icon' onClick={handleClick}>
                    <i className={click ? 'fas fa-times' : 'fas fa-bars'}/>
                </div>

                <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                    <li>
                        <NavLink exact to="/" className="nav-links" activeClassName="nav-links-active"
                                 onClick={closeMobileMenu}>
                            Home
                        </NavLink>
                    </li>
                    <li>
                        <NavLink exact to="/faq" className="nav-links" activeClassName="nav-links-active"
                                 onClick={closeMobileMenu}>
                            FAQ
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/shop" className="nav-links" activeClassName="nav-links-active"
                                 onClick={closeMobileMenu}>
                            Shop
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/cart" className="nav-links" activeClassName="nav-links-active"
                                 onClick={closeMobileMenu}>
                            <i className="fas fa-shopping-cart"/> {props.cartQuantity} (${props.cartAmount.toFixed(2)})
                        </NavLink>
                    </li>
                </ul>
            </div>
        </nav>
    )
}

export default withRouter(Navbar)
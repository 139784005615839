import React, { useEffect, useState } from "react";
import "../App.css";
import "./Cart.css";
import { useHistory } from "react-router-dom";
import { getSizeNameFromProductType } from "../Helper";

function CartItem(props) {
  const history = useHistory();

  const removeItemFromCart = (e) => {
    e.preventDefault();

    let cart = JSON.parse(localStorage.getItem("cart"));
    let cartId = props.cartId;

    let items = cart.items.filter(function (item, i) {
      return i !== cartId;
    });

    let newCart = {
      items: items,
    };

    localStorage.setItem("cart", JSON.stringify(newCart));

    history.go(0);
  };

  const [state, setState] = useState({
    images: null,
  });

  useEffect(() => {
    let images = [];
    for (let i = 0; i < props.item.itemImagePath.length; i++) {
      let item = props.item.itemImagePath[i];
      images.push(
        <a
          key={i}
          href={process.env.PUBLIC_URL + "/uploads/" + item}
          target="_blank"
          rel="noreferrer"
        >
          Uploaded Image {i + 1}
        </a>
      );
    }

    setState((state) => ({
      ...state,
      images: images,
    }));
  }, [props]);

  return (
    <tr>
      <td>
        <div className="item-info">
          <img
            src={props.item.imagePath + "/1.png"}
            alt={"Image of " + props.item.name}
          />
          <div>
            <p>{props.item.name}</p>
            <small>
              Price: {"$" + props.item.price.toFixed(2)}
              {props.item.frontPanel
                ? " (Front Panel " +
                  (props.item.backPanel ? "& Back Panel " : "") +
                  " Included)"
                : null}
            </small>
            {props.item.productType === 2 ? (
              <div>
                <div>
                  <small>
                    Amount:{" "}
                    {getSizeNameFromProductType(
                      props.item.productType,
                      props.item.size
                    )}
                  </small>
                </div>
              </div>
            ) : (
              <div>
                <div>
                  <small>
                    Size:{" "}
                    {props.item.size.charAt(0).toUpperCase() +
                      props.item.size.slice(1)}
                    , Length: {props.item.length} inches, Width:{" "}
                    {props.item.width} inches, Height: {props.item.height}{" "}
                    inches
                  </small>
                </div>
                <div>
                  <small>Case Name/Case Link: {props.item.caseName}</small>
                </div>
              </div>
            )}
            <div>{state.images}</div>
            <div>
              {state.images !== null && state.images.length > 0 ? (
                <small>
                  Your original images are uploaded for image quality purposes,
                  but we will use your crop for the final product.
                </small>
              ) : null}
            </div>
            <div>
              <small>Additional Comments: {props.item.comments}</small>
            </div>
            <div>
              <a href="/cart" onClick={removeItemFromCart}>
                Remove
              </a>
            </div>
          </div>
        </div>
      </td>
      <td>{"$" + props.item.price.toFixed(2)}</td>
    </tr>
  );
}

export default CartItem;

import React from 'react';
import '../../App.css';
import FAQ from '../FAQ';
import Footer from '../Footer';
import MetaTags from "react-meta-tags";

function FAQPage(){
    return(
        <div>
            <MetaTags>
                <title>AniPC - FAQ</title>
                <meta id="meta-description" name="description" content="Find answers to questions about our services here!" />
            </MetaTags>
            <FAQ />
            <Footer />
        </div>
    )
}

export default FAQPage
import React from 'react';
import '../../App.css';
import TrackOrder from '../TrackOrder';
import Footer from '../Footer';
import MetaTags from "react-meta-tags";

function TrackOrderPage(){
    return(
        <div>
            <MetaTags>
                <title>AniPC - Track Order</title>
            </MetaTags>
            <TrackOrder/>
            <Footer />
        </div>
    )
}

export default TrackOrderPage
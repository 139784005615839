import React from 'react';
import '../App.css';
import './ProductDetails.css';
import { Link as SmoothLink } from "react-scroll";

function ProductDetailsLeft(props){
    function changeActive(event){
        document.querySelectorAll('img.active').forEach(function(item){
            item.classList.remove('active');
        })
        event.target.classList.add('active');
    }

    return(
        <div className="details-left-container">
            <div className="product-view">
                <div className="smooth-link">
                    <SmoothLink
                        activeClass="active"
                        to="front"
                        spy={true}
                        smooth={true}
                        offset={-60}
                        duration={500}
                    >
                        <img src={props.front} alt="" onClick={changeActive}></img>
                    </SmoothLink>
                </div>
                <div className="smooth-link">
                    <SmoothLink
                        activeClass="active"
                        to="side"
                        spy={true}
                        smooth={true}
                        offset={-60}
                        duration={500}
                    >
                        <img src={props.side} alt="" onClick={changeActive}></img>
                    </SmoothLink>
                </div>
                <div className="smooth-link">
                    <SmoothLink
                        activeClass="active"
                        to="back"
                        spy={true}
                        smooth={true}
                        offset={-60}
                        duration={500}
                    >
                        <img src={props.back} alt="" onClick={changeActive}></img>
                    </SmoothLink>
                </div>
            </div>
        </div>
    )
}

export default ProductDetailsLeft
import React from 'react';
import '../App.css';
import './Policy.css';

function ReturnPolicy() {
    return (
        <div className="refund-container">
            <h1>Refund and Exchange Policy</h1>
            <h2>RETURNS & REFUNDS</h2>
            <p>AniPC will offer returns and refunds only when the following conditions are met:</p>
            <ul>
                <li>The product(s) is unopened, undamaged, and unused</li>
                <li>The product(s) is shipped back to AniPC within 14 business days of receiving the product(s)</li>
            </ul>
            <p><strong>DISCLAIMER: The buyer will be responsible for paying for shipping costs of returns</strong></p>
            <p>Upon receiving the returned products, AniPC will inspect the product(s). After clear inspection of the
                product(s) refunds shall be issued according to your order.</p>
            <h2>EXCHANGES</h2>
            <p>AniPC will offer exchanges only when the following conditions are met:</p>
            <ul>
                <li>The product(s) is unopened, undamaged, and unused</li>
                <li>The product(s) is shipped back to AniPC within 14 business days of receiving the product(s)</li>
            </ul>
            <p><strong>DISCLAIMER: The buyer will be responsible for paying for shipping costs of returns</strong></p>
            <p>Upon receiving the returned product(s), AniPC will inspect the product(s). After inspection, AniPC will
                ship out the replacement product(s), and provide the tracking information for the package. Exchanges can
                ONLY be applied to products that have the same value. For exchanges, contact <a
                    href="mailto:support@anipc.net">support@anipc.net</a></p>
            <p><strong>All Refunds, Returns, Exchanges, and so on can be REJECTED or DENIED under the jurisdiction of AniPC at any time.</strong></p>
        </div>
    )
}

export default ReturnPolicy

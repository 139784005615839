import React, {Component} from 'react';
import '../App.css';
import './Catalog.css';
import CatalogCard from './CatalogCard';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Link} from "react-router-dom";

class Catalog extends Component {
    state = {
        loading: true,
        catalog: []
    }

    componentDidMount() {
        fetch('/api/catalog').then(async res => {
            const json = await res.json()

            // noinspection JSUnresolvedVariable
            if (json.success) {
                // noinspection JSUnresolvedVariable
                this.setState({
                    loading: false,
                    catalog: json.catalog
                })
            }
        })
    }

    render() {
        if (this.state.loading) {
            return null
        } else {
            /* Solution: https://stackoverflow.com/questions/42391499/react-render-new-row-every-4th-column */

            const rows = [...Array(Math.ceil(this.state.catalog.length / 4))];
            const productRows = rows.map((row, idx) => this.state.catalog.slice(idx * 4, idx * 4 + 4));
            const content = productRows.map((row, idx) => (
                <div className="row" key={idx}>
                    {row.map(category =>
                        <CatalogCard key={category.id} id={category.id} title={category.name}
                                     imagePath={category.imagePath + "/preview"} imageExtension={".png"}/>
                    )}
                </div>)
            );

            return (
                <div className="catalog-container">
                    <div className="catalog-banner" style={{backgroundImage: `url('/images/shop/banner.png')`}}>

                    </div>

                    <div className="catalog-content container-fluid">
                        {content}
                        <div className="view-more-button">
                            <Link to="/collection">View All Products</Link>
                        </div>
                    </div>
                </div>
            )
        }
    }
}

export default Catalog
import React, { useEffect, useState } from "react";
import "../App.css";
import "./CheckOut.css";
import CheckOutItem from "./CheckOutItem";
import "bootstrap/dist/css/bootstrap.min.css";
import { getCartDetails } from "../Helper";
import { Link } from "react-router-dom";
import { Redirect } from "react-router-dom";
import PayPal from "./PayPal";

function CheckOut() {
  const [state, setState] = useState({
    loading: true,
    redirect: false,
    cart: {
      items: [],
    },
    cartTotal: 0,
    shippingAmount: 0,
    checkOutItem: [],
    promoCode: {
      code: "",
      value: 0,
      isPercent: false,
    },
    success: null,
    error: "",
  });

  useEffect(() => {
    getCartDetails().then((res) => {
      const checkOutItem = [];

      if (res.cart.items.length === 0) {
        setState((state) => ({
          ...state,
          redirect: true,
        }));

        return;
      }

      for (let i = 0; i < res.cart.items.length; i++) {
        checkOutItem.push(
          <CheckOutItem key={i} item={res.cart.items[i]} inCheckout={true} />
        );
      }

      setState((state) => ({
        ...state,
        loading: false,
        cart: res.cart,
        cartTotal: res.cartTotal,
        shippingAmount: 0,
        checkOutItem: checkOutItem,
      }));
    });
  }, []);

  const handlePromoCodeInput = (e) => {
    e.preventDefault();

    setState((state) => ({
      ...state,
      promoCode: {
        code: e.target.value,
        value: state.promoCode.value,
        isPercent: state.promoCode.isPercent,
      },
      success: null,
    }));
  };

  const applyPromoCode = () => {
    fetch("/api/promocode", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        promoCode: state.promoCode.code,
      }),
    }).then(async (res) => {
      let json = await res.json();

      if (json.success) {
        setState((state) => ({
          ...state,
          promoCode: {
            code: json.code,
            value: json.value,
            isPercent: json.isPercent,
          },
          success: json.success,
          error: json.error,
        }));
      } else {
        setState((state) => ({
          ...state,
          promoCode: {
            code: "",
            value: 0,
            isPercent: false,
          },
          success: json.success,
          error: json.error,
        }));
      }
    });
  };

  const getSavingInDollars = () => {
    let savings = 0;

    if (state.promoCode.isPercent) {
      savings = state.cartTotal * state.promoCode.value;
    } else {
      savings = state.promoCode.value;
    }

    return savings;
  };

  return state.redirect ? (
    <Redirect to="/cart" />
  ) : state.loading ? null : (
    <div className="checkout-container">
      <div className="order-summary">
        <div className="checkout-head">
          <picture>
            <source srcSet={"images/logo2.webp"} />
            <img src={"images/logo2.png"} alt="AniPC Logo" />
          </picture>
          <p>
            <Link to="cart">Cart</Link> &gt; Payment
          </p>
        </div>
        {state.checkOutItem}
        <div className="discount-code">
          <div className="form-row">
            <div className="form-group col-md-8">
              <input
                type="text"
                className={
                  state.success === null
                    ? "form-control"
                    : state.success
                    ? "form-control is-valid"
                    : "form-control is-invalid"
                }
                id="discount"
                placeholder="Discount Code"
                value={state.promoCode.code}
                onChange={handlePromoCodeInput}
              />
            </div>

            <button
              type="submit"
              className="btn btn-primary col-md-4"
              onClick={applyPromoCode}
            >
              Apply
            </button>
          </div>

          <div>
            <small>{state.error}</small>
          </div>
          <div>
            <small>
              You may only use one promo code. Any promo code applied will
              replace previous code.
            </small>
          </div>
        </div>
        <div className="subtotal-price-container">
          <div className="subtotal-price">
            <p>Subtotal</p>
            <p>${state.cartTotal.toFixed(2)}</p>
          </div>
          {/*<div className="subtotal-price">
                        <p>Estimated Shipping</p>
                        <p>{state.shippingAmount === 0 ? "FREE" : "$" + state.shippingAmount.toFixed(2)}</p>
                    </div>
                    <div className="subtotal-price">
                        <p>Estimated Taxes</p>
                        <p>FREE</p>
                    </div>*/}
          <div className="subtotal-price">
            <p>Savings</p>
            <p>-${getSavingInDollars().toFixed(2)}</p>
          </div>
        </div>
        <div className="final-price">
          <p>Estimated Total</p>
          <p>
            $
            {(
              state.cartTotal +
              state.shippingAmount -
              getSavingInDollars()
            ).toFixed(2)}
          </p>
        </div>
      </div>
      <div className="checkout-content">
        <PayPal
          promoCode={state.promoCode.code}
          savings={getSavingInDollars()}
        />
      </div>
    </div>
  );
}

export default CheckOut;

import React, {useEffect, useState} from 'react';
import '../App.css';
import './OrderComplete.css';
import CheckOutItem from './CheckOutItem';
import {useLocation} from "react-router-dom";
import {getCroppedImg, getItemDetailFromId, getProductPriceFromSize, getSizeNameFromProductType} from "../Helper";

const queryString = require('query-string');
const getSymbolFromCurrency = require('currency-symbol-map')

function TrackOrder() {
    const [state, setState] = useState({
        loading: true,
        orderStatus: "",
        orderId: "",
        currency: "$",
        email: "",
        shippingAddress: [],
        shipped: false,
        trackingNumber: null,
        trackingURL: null,
        totalPrice: 0,
        purchasedItems: [],
        croppedImages: [],
        uploadedImages: [],
        hideCroppedImages: false,
        hideOriginalImages: true
    });

    const toggleCroppedImages = () => {
        setState(state => ({
            ...state,
            hideCroppedImages: !state.hideCroppedImages
        }))
    }

    const toggleOriginalImages = () => {
        setState(state => ({
            ...state,
            hideOriginalImages: !state.hideOriginalImages
        }))
    }

    const location = useLocation()

    useEffect(() => {
        let queryStringValues = queryString.parse(location.search)

        if (queryStringValues.orderId !== undefined) {
            fetch('/api/orderDetails?orderId=' + queryStringValues.orderId).then(async res => {
                let orderDetails = await res.json()

                const purchasedItems = []
                const croppedImages = []
                const uploadedImages = []

                for (let i = 0; i < orderDetails.purchasedItems.length; i++) {
                    // TODO: In the future, make API automatically pull all this information without needing client to do.
                    let item = await getItemDetailFromId(orderDetails.purchasedItems[i].productId);
                    item.frontPanel = orderDetails.purchasedItems[i].frontPanel;
                    item.backPanel = orderDetails.purchasedItems[i].backPanel;
                    item.images = JSON.parse(orderDetails.purchasedItems[i].resizeParameters);
                    item.imagePath = item.imageFolderPath;
                    item.itemImagePath = orderDetails.purchasedItems[i].itemImagePath
                    item.size = getSizeNameFromProductType(item.productTypeId, orderDetails.purchasedItems[i].size);
                    item.productType = item.productTypeId;
                    item.price = getProductPriceFromSize(item);
                    item.length = orderDetails.purchasedItems[i].length;
                    item.width = orderDetails.purchasedItems[i].width;
                    item.height = orderDetails.purchasedItems[i].height;
                    item.caseName = orderDetails.purchasedItems[i].caseName;
                    item.comments = orderDetails.purchasedItems[i].customerComment;
                    purchasedItems.push(<CheckOutItem key={i} item={item} inCheckout={false}/>)

                    let json = JSON.parse(orderDetails.purchasedItems[i].itemImagePath)
                    let json2 = JSON.parse(orderDetails.purchasedItems[i].resizeParameters)

                    if (json2 !== null) {
                        for (let j = 0; j < json2.length; j++) {
                            if (json2[j].resizeParameters.croppedAreaPixels !== null) {
                                let croppedImage = await getCroppedImg("uploads/" + json[j], json2[j].resizeParameters.croppedAreaPixels)
                                let src = URL.createObjectURL(croppedImage)
                                croppedImages.push(<img key={"cropped_" + i + "_" + j} src={src}
                                                        alt={"Cropped Image " + j}/>)
                            }
                        }
                    }

                    for (let j = 0; j < json.length; j++) {
                        uploadedImages.push(<img key={"uploaded_" + i + "_" + j} src={"uploads/" + json[j]}
                                                 alt={"Uploaded Image " + j}/>)
                    }
                }

                setState(state => ({
                    ...state,
                    loading: false,
                    orderStatus: orderDetails.status,
                    orderId: queryStringValues.orderId,
                    currency: getSymbolFromCurrency(orderDetails.currency),
                    totalPrice: orderDetails.totalPrice,
                    purchasedItems: purchasedItems,
                    uploadedImages: uploadedImages,
                    croppedImages: croppedImages,
                    email: orderDetails.email,
                    shippingAddress: orderDetails.shippingAddress,
                    shipped: orderDetails.shipped,
                    trackingNumber: orderDetails.trackingNumber,
                    trackingURL: orderDetails.trackingURL
                }))
            })
        }
        // eslint-disable-next-line
    }, [])

    return state.loading ? null : (
        <div className="order-complete-container">
            <div className="order-complete-outer">
                <div className="order-complete-inner">
                    <h1>Purchased Items</h1>
                    {state.purchasedItems}
                    <h2>If there are any incorrect information, email us at <a
                        href="mailto:support@anipc.net">support@anipc.net</a></h2>
                    <div className="order-information">
                        <h3>Order Information</h3>
                        <p>Order Status: {state.orderStatus}</p>
                        <p>Order ID: {state.orderId}</p>
                        <p>Email Address: {state.email}</p>
                        <p>Total Price Paid: {state.currency + state.totalPrice}</p>
                    </div>
                    <div className="order-shipping-information">
                        <h3>Shipping Information</h3>
                        <p>Address: {state.shippingAddress.address}</p>
                        <p>Alternative Address: {state.shippingAddress.address2}</p>
                        <p>City: {state.shippingAddress.city}</p>
                        <p>State: {state.shippingAddress.state}</p>
                        <p>Postal Code: {state.shippingAddress.zipcode}</p>
                        <p>Country: {state.shippingAddress.country}</p>
                    </div>
                    {!state.shipped ? null : <div className="order-shipping-information">
                        <h3>Tracking Information</h3>
                        <p>Tracking Number: <a href={state.trackingURL}>{state.trackingNumber}</a></p>
                    </div>}
                    {state.uploadedImages.length > 0 ? <div className="order-images">
                        <button type="button" className="image-toggle-button"
                                onClick={toggleCroppedImages}>{state.hideCroppedImages ? "Unhide Cropped Images" : "Hide Cropped Images"}</button>
                        <div hidden={state.hideCroppedImages}>
                            <h3>Uploaded (Cropped) Image(s)</h3>
                            {state.croppedImages}
                        </div>
                        <button type="button" className="image-toggle-button"
                                onClick={toggleOriginalImages}>{state.hideOriginalImages ? "Unhide Original Images" : "Hide Original Images"}</button>
                        <div hidden={state.hideOriginalImages}>
                            <h3>Original Image(s)</h3>
                            {state.uploadedImages}
                        </div>
                    </div> : null}
                </div>
            </div>
        </div>
    )
}

export default TrackOrder
import React, { useEffect, useState } from "react";
import "../App.css";
import "./CheckOut.css";
import { PayPalButton } from "react-paypal-button-v2";
import { Redirect } from "react-router-dom";
import { getCartDetails } from "../Helper";

function PayPal(props) {
  const [state, setState] = useState({
    loading: true,
    redirect: false,
    orderId: "",
    amount: 0.0,
    cart: [],
  });

  useEffect(() => {
    getCartDetails().then((res) => {
      fetch("/api/pre-checkout", {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          cart: res.cart,
        }),
      }).then(async (res2) => {
        let response = await res2.json();
        let amount = response.cartTotal;
        //console.log(res.cart)
        setState({
          ...state,
          loading: false,
          amount: amount - props.savings,
          cart: res.cart,
        });
      });
    });
    // eslint-disable-next-line
  }, [props]);

  return state.loading ? null : state.redirect ? (
    <Redirect to={"/order-complete?orderId=" + state.orderId} />
  ) : (
    <div className="shipping-information">
      <h1>Checkout</h1>
      <p>
        After paying on PayPal, please wait a moment, and you'll be redirected.
      </p>
      <div className="paypal">
        <PayPalButton
          amount={state.amount.toFixed(2)}
          style={{
            layout: "vertical",
            color: "gold",
            shape: "rect",
            label: "paypal",
          }}
          shippingPreference="GET_FROM_FILE"
          capture="authorize"
          onSuccess={async (details) => {
            let div = document.createElement("div");
            div.className = "post-checkout";
            document.body.appendChild(div);
            let loader = document.createElement("div");
            loader.className = "loader";
            div.appendChild(loader);
            fetch("/api/checkout", {
              method: "post",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                details: details,
                promoCode: props.promoCode,
                cart: state.cart,
              }),
            }).then((res) => {
              div.remove();
              if (res.status === 201) {
                setState({
                  ...state,
                  redirect: true,
                  orderId: details.id,
                });

                localStorage.removeItem("cart");

                return true;
              } else if (res.status === 400) {
                alert("Invalid API input. Please try again.");
              } else {
                alert(
                  "Order did not successfully save on our database, but we received your payment. Please email support@anipc.net with your order, which you can screenshot from your cart. Sorry for the inconvenience."
                );
                alert("Your Order ID is: " + details.id);
              }
            });
          }}
          onShippingChange={async (data, actions) => {
            let shippingCost = 0.0;

            if (data.shipping_address.country_code !== "US") {
              let res = await fetch("/api/international-shipping", {
                method: "post",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  cart: state.cart,
                }),
              });
              let json = await res.json();
              shippingCost = json.shippingCost;
            }

            return actions.order.patch([
              {
                op: "replace",
                path: "/purchase_units/@reference_id=='default'/amount",
                value: {
                  currency_code: "USD",
                  value: (state.amount + shippingCost).toFixed(2),
                  breakdown: {
                    item_total: {
                      currency_code: "USD",
                      value: state.amount.toFixed(2),
                    },
                    shipping: {
                      currency_code: "USD",
                      value: shippingCost.toFixed(2),
                    },
                  },
                },
              },
            ]);
          }}
          onError={(details) => {
            console.error(details);
            alert("PayPal is having service errors, please try again.");
          }}
        />
      </div>
    </div>
  );
}

export default PayPal;

import React from 'react';
import '../App.css';
import './Team.css';

function TeamCard(props) {
    return (
        <div className="teamcard-container">
            <div className="cover-image">
                <img src={props.coverimg} alt=""/>
            </div>
            <div className="profile-image">
                <img src={props.profileimg} alt=""/>
            </div>
            <div className="person-content">
                <h2>{props.name}</h2>
                <h3>{props.position}</h3>
                <p>{props.info}</p>
            </div>
            <div className="social-icons">
                {/*<a href={props.facebook} target="_blank" rel="noreferrer">
                    <i class="fab fa-facebook-f"></i>
                </a>
                <a href={props.instagram} target="_blank" rel="noreferrer">
                    <i class="fab fa-instagram"></i>
                </a>
                <a href={props.link} target="_blank" rel="noreferrer">
                    <i class="fas fa-link"></i>
                </a>*/}
            </div>
        </div>
    )
}

export default TeamCard

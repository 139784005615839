import React from 'react';
import '../App.css';
import './FAQ.css';

function FAQ() {
    return (
        <div className="faq-container">
            <h1 id="title">FAQ</h1>
            <h1>Where is your store located?</h1>
            <h2>We do not have a physical store but we are based and located in New York City.</h2>
            <h1>How long will shipping take?</h1>
            <h2>Orders will take anywhere from 1-5 to be processed by AniPC. Please allow an extra 7 days for processing
                custom PC case designs and custom PC Builds. Domestic orders (within the US) will take 3-5 business days
                with USPS First Class and tracking included. International orders will take anywhere from 14-21 business
                days.</h2>
            <h2><em>Shipping times are subjected to change due to the COVID-19 Pandemic.</em></h2>
            <h1>How will I know when my order is shipped?</h1>
            <h2>You will receive an email confirmation from us with a tracking number when we have packaged and shipped
                out your order.</h2>
            <h1>What happens when I accidentally put the wrong information for my order?</h1>
            <h2>We are not responsible for any incorrect information on your specific order. All sales are final unless
                they are subject to our return, refund, and exchange policy so please carefully review that all of your
                information is correct before placing your order.</h2>
            <h1>What are the duties and taxes for International Orders?</h1>
            <h2>Customs duties and taxes are not covered in our shipping rate and are the responsibility of the
                recipient. We recommend you check your appropriate service guide or contact the local customs office for
                country-specific billing information.</h2>
            <h1>What payment methods are accepted?</h1>
            <h2>We support PayPal, Credit/Debit Card purchases.</h2>
            <h1>What is the best way to apply and maintain AniPC’s vinyl products?</h1>
            <div className="video-container">
                <iframe title="AniPC Case Skin Tutorial" width="560" height="315"
                        src="https://www.youtube.com/embed/Q5lnOayVYPM" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen/>
            </div>
            <h2>
                We recommend applying our products to clean, dry and flat surfaces.<br/>
                For products besides case skins please remove the white layer and apply to surface.<br/>
                To apply case skins:</h2>
            <ol>
                <li>Please clean the surface of application and make that there is NO moisture (moisture will make
                    the skin eventually fall off the surface).
                </li>
                <li>Please remove the white film on the back of the skin and make sure that the skin stays
                    on the transparent film.
                </li>
                <li>Once removed start by applying the skin starting from the bottom. To start please start from
                    the middle of the skin and push out to the left and right. So starting from the bottom apply the
                    middle then apply left/right. Then move up a bit and rinse and repeat. It is recommended to use
                    using a squeegee or a thick card to apply (thin cards may damage the artwork).
                    If there are any large air bubbles please slowly peel the skin off the surface and reapply the
                    area where there are large air bubbles.
                </li>
                <li>Once the skin is applied please carefully remove the transparent film from the skin.</li>
                <li>Once the film is removed, to remove any smaller air bubbles please use a thin needle and a squeegee.
                    Simply poke the air bubble with the needle and then use the squeegee to remove the air inside. THIS
                    DOES
                    NOT WORK with larger air bubbles, the larger air bubbles when taken care of with this method will
                    leave
                    creases and damage the artwork.
                </li>
                <li>To remove excess carefully use a sharp item (we recommend an exacto knife) and run it along
                    the edge of the the surface.
                </li>
            </ol>
            <h2>Please do not hesitate to email <a href="mailto:info@anipc.net">info@anipc.net</a> for further
                information! :)</h2>

            <h1>Do the premade skins only fit a specified amount of certain cases like the NZXT H510?</h1>
            <h2>No, we use the NZXT H510 case only as a display/template. All of our vinyl wraps and skins are supported
                by normal-shaped cases (squares and rectangles) that fit the mini-atx, mid-tower, and full tower
                sizes.</h2>
            <h2>If you have a semi-irregular or irregular case please choose the custom option to make sure that our
                wraps can fit perfectly onto your case. We also recommend trimming any excess with an exacto knife to
                get the best fit on your case.</h2>
            <h2>We recommend adding excess to the dimensions to ensure the best fit for your case. A maximum of 1/2 an
                inch is all that would be necessary.</h2>
            <h1>How do the custom PC skins and stickers option work?</h1>
            <h2>To create your own custom PC skin, select the custom option, and choose your specific case with its
                respective size. After selecting your case, you can upload your images and position them any way you
                like on your own design. Be sure to position your design the way your case is because that will be the
                final product.</h2>
            <h2>For creating custom stickers, select the number of stickers you would like to purchase along with the
                size and upload any image you would like as a sticker in the sized boxes it gives you. Any image cut off
                by the border of the boxes will be cut off as the sticker. Also, make sure you position stickers on the
                website the way you would like them because the stickers will turn out exactly how you format them.</h2>
        </div>
    )
}

export default FAQ

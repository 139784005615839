import React, {useEffect, useState} from 'react';
import '../App.css';
import './ItemFooter.css';
import {Link} from 'react-router-dom';
import {getCartDetails} from "../Helper";

function ItemFooter(){
    const [state, setState] = useState({
        loading: true,
        cart: {
            items: []
        },
        cartTotal: 0
    });

    useEffect(() => {
        getCartDetails().then(res => {
            setState(state => ({
                ...state,
                loading: false,
                cart: res.cart,
                cartTotal: res.cartTotal
            }))
        })
    }, [])

    return state.loading || state.cart.items.length === 0 ? null : (
        <div className="item-footer-container">
            <div className="item-count">
                <Link to="/cart">{state.cart.items.length} items</Link>
            </div>
            <div className="total-price">
                <p>${state.cartTotal.toFixed(2)}</p>
            </div>
            <div className="checkout-button">
                <Link to="/checkout">Check Out</Link>
            </div>

        </div>
    )
}

export default ItemFooter
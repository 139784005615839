import React, {useEffect, useState} from 'react';
import '../App.css';
import './CheckOutItem.css';
import {getSizeNameFromProductType} from "../Helper";

function CheckOutItem(props) {
    const [state, setState] = useState({
        images: [],
        hideInformation: true
    });

    const onExpandButton = () => {
        setState(state => ({
            ...state,
            hideInformation: !state.hideInformation
        }))
    }

    useEffect(() => {
        if (props.item.itemImagePath !== null) {
            let itemImagePath = props.item.itemImagePath

            if (!props.inCheckout) {
                itemImagePath = JSON.parse(props.item.itemImagePath)
            }

            if (itemImagePath.length > 0) {
                let images = []

                for (let i = 0; i < itemImagePath.length; i++) {
                    let item = itemImagePath[i]
                    images.push(<div key={i} className="checkout-item-info-images">
                        <a href={process.env.PUBLIC_URL + "/uploads/" + item} target="_blank"
                           rel="noreferrer">Uploaded
                            Image {(i + 1)}</a>
                        {props.item.productType === 2 ?
                            <p>| Transparent Background: {props.item.images[i].isTransparent ? "Yes" : "No"}</p> : null}
                    </div>)
                }

                setState(state => ({
                    ...state,
                    images: images
                }))
            }
        }
    }, [props])

    return (
        <div>
            <div className="checkout-item">
                <img src={props.item.imagePath + "/1.png"} alt={props.item.name}/>
                <div className="checkout-item-info">
                    <div className="test">
                        <p>{props.item.name}</p>
                        <div>
                            <small>Amount/Size: {getSizeNameFromProductType(props.item.productType, props.item.size)}</small>
                        </div>
                        <div>
                            {/*<small>{props.inCheckout ? "Return to cart to view options." : "Options and/or comments are saved on our end. Email us at support@anipc.net, if you would like to change or request anything."}</small>*/}
                            {state.hideInformation ? <button onClick={onExpandButton}>Show Details</button> :
                                <button onClick={onExpandButton}>Hide Details</button>}
                        </div>
                    </div>
                    <p className="right">Price:
                        ${typeof props.item.price === 'number' ? props.item.price.toFixed(2) : props.item.price}</p>
                </div>
            </div>
            <div className="checkout-item-info-expanded" hidden={state.hideInformation}>
                {props.item.productType === 1 ? <div>
                    <div>
                        {props.item.frontPanel ? <small>Additional Front Panel (+$7.00)</small> : null}
                    </div>
                    <div>
                        {props.item.backPanel ? <small>Additional Back Panel (+$14.00)</small> : null}
                    </div>
                    <div>
                        <small>Case Dimensions: Length: {props.item.length}", Width: {props.item.width}",
                            Height: {props.item.height}"</small>
                    </div>
                    <div>
                        <small>Case Name or Link: {props.item.caseName}</small>
                    </div>
                </div> : null}
                <div>
                    <div>
                        {state.images}
                    </div>
                </div>
                <div>
                    <small>Additional Comments: {props.item.comments}</small>
                </div>
            </div>
        </div>
    )
}

export default CheckOutItem
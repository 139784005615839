import React, { useState } from "react";
import "./App.css";
import Navbar from "./components/Navbar";
import HomePage from "./components/pages/HomePage";
import ShopPage from "./components/pages/ShopPage";
import FAQPage from "./components/pages/FAQPage";
import ProductPage from "./components/pages/ProductPage";
import CollectionPage from "./components/pages/CollectionPage";
import AboutPage from "./components/pages/AboutPage";
import CartPage from "./components/pages/CartPage";
import CheckOutPage from "./components/pages/CheckOutPage";
import TOSPage from "./components/pages/TOSPage";
import PrivacyPage from "./components/pages/PrivacyPage";
import ReturnPolicyPage from "./components/pages/ReturnPolicyPage";
import OrderCompletePage from "./components/pages/OrderCompletePage";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import TrackOrderPage from "./components/pages/TrackOrderPage";
import NotificationBar from "./components/NotificationBar";
import withClearCache from "./ClearCache";
import packageJson from "../package.json";
import { getBuildDate } from "./Helper";

const ClearCacheComponent = withClearCache(MainApp);

function App() {
  return <ClearCacheComponent />;
}

function MainApp(props) {
  /*document.addEventListener("contextmenu", function(e){
      e.preventDefault();
    }, false);*/

  const [state, setState] = useState({
    cartQuantity: 0,
    cartAmount: 0,
    notificationActive: false,
    notificationType: "alert-warning", // https://getbootstrap.com/docs/4.0/components/alerts/
    notificationTitle: "Notice",
    notificationMessage: "",
  });

  const updateCart = (quantity, amount) => {
    if (quantity !== state.cartQuantity || amount !== state.cartAmount) {
      setState({
        ...state,
        cartQuantity: quantity,
        cartAmount: amount,
      });
    }
  };

  return (
    <BrowserRouter>
      <ScrollToTop />
      <div className="App">
        <NotificationBar
          visible={state.notificationActive}
          title={state.notificationTitle}
          message={state.notificationMessage}
          type={state.notificationType}
        />
        <Navbar
          cartQuantity={state.cartQuantity}
          cartAmount={state.cartAmount}
        />
        <Switch>
          <Route path="/" exact component={HomePage} />
          <Route path="/faq" exact component={FAQPage} />
          <Route path="/shop" exact component={ShopPage} />
          <Route path="/collection" exact component={CollectionPage} />
          <Route path="/product" component={ProductPage} />
          <Route path="/about" component={AboutPage} />
          <Route
            path="/cart"
            component={() => <CartPage updateCart={updateCart} />}
          />
          <Route path="/checkout" component={CheckOutPage} />
          <Route path="/terms-of-service" component={TOSPage} />
          <Route path="/privacy-policy" component={PrivacyPage} />
          <Route path="/return-policy" component={ReturnPolicyPage} />
          <Route path="/order-complete" component={OrderCompletePage} />
          <Route path="/track-order" component={TrackOrderPage} />
        </Switch>
        <p hidden={true}>Build date: {getBuildDate(packageJson.buildDate)}</p>
      </div>
    </BrowserRouter>
  );
}

export default App;

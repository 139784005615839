import React from 'react';
import '../App.css';
import './ProductCard.css';
import {Link} from 'react-router-dom';

function ProductCard(props) {
    return (
        <div className="col-md">
            <div className="product-card-container">
                <div className="product-card">
                    <div className="product-image">
                        <picture>
                            <source srcSet={props.imagePath + ".webp"}/>
                            <img src={props.imagePath + props.imageExtension} alt={"Image of " + props.title} width="100%" height="100%"/>
                        </picture>
                    </div>
                    <div className="product-content">
                        <div className="product-content-box">
                            <h1>{props.title}</h1>
                            <h2>({props.category})</h2>
                        </div>
                        <ul className="size">
                            <li>
                                <Link to={"/product?size=small&id=" + props.id}>{props.productType === 1 ? "Mini Tower" : "3 Pack"}</Link>
                            </li>
                            <li>
                                <Link to={"/product?size=medium&id=" + props.id}>{props.productType === 1 ? "Mid Tower" : "5 Pack"}</Link>
                            </li>
                            <li>
                                <Link to={"/product?size=large&id=" + props.id}>{props.productType === 1 ? "Full Tower" : "10 Pack"}</Link>
                            </li>
                        </ul>
                    </div>
                    <div className={props.sale ? "sale" : "notsale"}>
                        <p>Sale</p>
                    </div>
                    <div className={props.new ? "new" : "notsale"}>
                        <p>New</p>
                    </div>
                </div>
                <h3>{props.title} <span
                    className={props.sale ? "line-through" : ""}>Starting at ${props.smallprice.toString()}</span>
                    <span
                        className={props.sale ? "sale-price" : "hidden"}> ${props.smallsale.toString()}</span>

                </h3>
            </div>
        </div>
    )
}

export default ProductCard
import React from 'react';
import '../../App.css';
import Products from '../Products';
import Footer from '../Footer';
import ItemFooter from "../ItemFooter";
import MetaTags from "react-meta-tags";

function CollectionPage(){
    return(
        <div>
            <MetaTags>
                <title>AniPC - Collections</title>
                <meta id="meta-description" name="description" content="Check out our collection of custom PC designs and stickers here." />
            </MetaTags>
            <Products 
                title="Naruto"
            />
            <ItemFooter/>
            <Footer />
        </div>
    )
}

export default CollectionPage
import React from 'react';
import '../../App.css';
import Team from '../Team';
import Footer from '../Footer';
import MetaTags from "react-meta-tags";

function AboutPage(){
    return(
        <div>
            <MetaTags>
                <title>AniPC - About Us</title>
                <meta id="meta-description" name="description" content="Meet the team behind AniPC here!" />
            </MetaTags>
            <Team />
            <Footer />
        </div>
    )
}

export default AboutPage
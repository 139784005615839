import React from 'react';
import '../../App.css';
import Catalog from '../Catalog';
import Footer from '../Footer';
import ItemFooter from "../ItemFooter";
import MetaTags from "react-meta-tags";

function ShopPage(){
    return(
        <div>
            <MetaTags>
                <title>AniPC - Shop</title>
                <meta id="meta-description" name="description" content="Check out our collection of custom PC designs and stickers here." />
            </MetaTags>
            <Catalog />
            <ItemFooter/>
            <Footer />
        </div>
    )
}

export default ShopPage
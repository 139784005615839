import React, { useState, useCallback } from "react";
import "../App.css";
import "./ProductDetails.css";
import { Redirect } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import ImageItem from "./ImageItem";

function ProductDetailsRight(props) {
  const [state, setState] = useState({
    productType: props.product.productTypeId,
    size: props.size,
    images: null,
    imageList: null,
    caseName: "",
    length: 0,
    width: 0,
    height: 0,
    comments: "",
    frontPanel: false,
    backPanel: false,
    redirect: false,
  });

  const getFileList = () => {
    let fileList = "";

    if (state.images !== null && state.images.length > 0) {
      for (let i = 0; i < state.images.length; i++) {
        if (i === 0) {
          fileList = state.images[i].name;
        } else {
          fileList = fileList + ", " + state.images[i].name;
        }
      }
      return fileList;
    } else {
      return "No files selected. (Maximum 10 MB)";
    }
  };

  const getRemainingImagesForSticker = (size) => {
    let remaining = 10;

    if (state.images !== null) {
      switch (size) {
        case "small":
          remaining = 3 - state.images.length;
          break;
        case "medium":
          remaining = 5 - state.images.length;
          break;
        case "large":
          remaining = 10 - state.images.length;
          break;
        default:
          break;
      }
    } else {
      switch (size) {
        case "small":
          remaining = 3;
          break;
        case "medium":
          remaining = 5;
          break;
        case "large":
          remaining = 10;
          break;
        default:
          break;
      }
    }

    return remaining;
  };

  const addCroppedImage = useCallback((files, index, parameter) => {
    let images = [...files];
    images[index].resizeParameters = parameter.resizeParameters;
    setState((state) => ({
      ...state,
      images: images,
    }));
  }, []);

  const setTransparentImage = useCallback((files, index, parameter) => {
    let images = [...files];
    images[index].isTransparent = parameter.isTransparent;
    setState((state) => ({
      ...state,
      images: images,
    }));
  }, []);

  const deleteImage = useCallback(
    (files, index) => {
      if (window.confirm("Are you sure you want to delete this image?")) {
        let newFiles = files.filter(function (v, i) {
          return index !== i;
        });

        let imageList =
          newFiles === null
            ? null
            : newFiles.map((file, index) => (
                <ImageItem
                  key={index}
                  productType={state.productType}
                  index={index}
                  files={newFiles}
                  file={file}
                  addCroppedImage={addCroppedImage}
                  setTransparentImage={setTransparentImage}
                  deleteImage={deleteImage}
                />
              ));

        setState((state) => ({
          ...state,
          images: newFiles,
          imageList: imageList,
        }));
      }
    },
    [state, addCroppedImage, setTransparentImage]
  );

  const saveFilesToState = useCallback(
    (uploadedFiles) => {
      const files = state.images === null ? [] : [...state.images];

      files.push(...uploadedFiles);

      let tooManyFiles = false;

      if (state.productType === 2) {
        if (state.size === "small" && files.length > 3) {
          alert("Too many images, only upload one image per sticker.");
          tooManyFiles = true;
        }
        if (state.size === "medium" && files.length > 5) {
          alert("Too many images, only upload one image per sticker.");
          tooManyFiles = true;
        }
        if (state.size === "large" && files.length > 10) {
          alert("Too many images, only upload one image per sticker.");
          tooManyFiles = true;
        }
      }

      if (files.length > 10) {
        alert(
          "More than 10 images selected. Maximum 10 images. If you have more images, email them to support@anipc.net after your order."
        );
      } else if (!tooManyFiles) {
        let imageList = files.map((file, index) => (
          <ImageItem
            key={index}
            productType={state.productType}
            index={index}
            files={files}
            file={file}
            addCroppedImage={addCroppedImage}
            setTransparentImage={setTransparentImage}
            deleteImage={deleteImage}
          />
        ));

        setState((state) => ({
          ...state,
          images: files,
          imageList: imageList,
        }));
      }
    },
    [state, addCroppedImage, deleteImage, setTransparentImage]
  );

  const onDrop = useCallback(
    (acceptedFiles) => {
      saveFilesToState(acceptedFiles);
    },
    [saveFilesToState]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const getPriceBasedOnSize = (size) => {
    let price = 0;

    switch (size) {
      case "small":
        price = props.product.priceSmall;
        break;
      case "medium":
        price = props.product.priceMedium;
        break;
      case "large":
        price = props.product.priceLarge;
        break;
      default:
        break;
    }

    return price;
  };

  const getSalesPrice = (price) => {
    return (price * (1 - props.product.salePercent / 100)).toFixed(2);
  };

  const clearImages = () => {
    setState({
      ...state,
      images: null,
      imageList: null,
    });
  };

  const changeSize = (e) => {
    if (state.productType === 2) {
      setState({
        ...state,
        size: e.target.value,
        images: null,
        imageList: null,
      });
    } else {
      setState({
        ...state,
        size: e.target.value,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let itemImagePath = [];

    if (state.images !== null) {
      const data = new FormData();

      for (let i = 0; i < state.images.length; i++) {
        data.append("images", state.images[i]);
      }

      let res = await fetch("/api/upload", {
        method: "POST",
        body: data,
      });

      let json = await res.json();

      for (let i = 0; i < json.fileNames.length; i++) {
        itemImagePath.push(json.fileNames[i]);
      }
    }

    let cart = {
      items: [
        {
          id: props.product.id,
          productType: state.productType,
          size: state.size,
          images: state.images,
          imagePaths: state.imagePaths,
          caseName: state.caseName,
          length: state.length,
          width: state.width,
          height: state.height,
          comments: state.comments,
          frontPanel: state.frontPanel,
          backPanel: state.backPanel,
          itemImagePath: itemImagePath,
        },
      ],
    };

    if (localStorage.getItem("cart") !== null) {
      let currentCart = JSON.parse(localStorage.getItem("cart"));

      for (let i = 0; i < currentCart.items.length; i++) {
        cart.items.push(currentCart.items[i]);
      }
    }

    localStorage.setItem("cart", JSON.stringify(cart));

    setState({
      ...state,
      redirect: true,
    });
  };

  const handleInput = (e) => {
    switch (e.target.id) {
      case "caseName":
        setState({
          ...state,
          caseName: e.target.value,
        });
        break;
      case "length":
        setState({
          ...state,
          length: e.target.value,
        });
        break;
      case "width":
        setState({
          ...state,
          width: e.target.value,
        });
        break;
      case "height":
        setState({
          ...state,
          height: e.target.value,
        });
        break;
      case "message":
        setState({
          ...state,
          comments: e.target.value,
        });
        break;
      case "frontPanel":
        setState({
          ...state,
          frontPanel: e.target.checked,
        });
        break;
      case "backPanel":
        setState({
          ...state,
          backPanel: e.target.checked,
        });
        break;
      default:
        break;
    }
  };

  return state.redirect ? (
    <Redirect to="/cart" />
  ) : (
    <div className="details-right-container">
      <div className="fixed-right">
        <h1>{props.product.name}</h1>
        <h2>({props.product.categoryName})</h2>
        <p>{props.product.description}</p>
        <form onSubmit={handleSubmit}>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="inlineRadioOptions"
              id="small"
              value="small"
              checked={state.size === "small"}
              onChange={changeSize}
            />
            <label className="form-check-label" htmlFor="small">
              {state.productType === 1 ? "Mini Tower" : "3 Pack"}
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="inlineRadioOptions"
              id="medium"
              value="medium"
              checked={state.size === "medium"}
              onChange={changeSize}
            />
            <label className="form-check-label" htmlFor="medium">
              {state.productType === 1 ? "Mid Tower" : "5 Pack"}
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="inlineRadioOptions"
              id="large"
              value="large"
              checked={state.size === "large"}
              onChange={changeSize}
            />
            <label className="form-check-label" htmlFor="large">
              {state.productType === 1 ? "Full Tower" : "10 Pack"}
            </label>
          </div>

          <h3>
            <span className={props.product.sale ? "line-through" : ""}>
              ${getPriceBasedOnSize(state.size)}
            </span>
            <span className={props.product.sale ? "sale-price" : "hidden"}>
              {" "}
              ${getSalesPrice(getPriceBasedOnSize(state.size))}
            </span>
          </h3>

          <div className="upload-custompic">
            <label htmlFor="uploadPicture">
              {props.custom
                ? state.productType === 1
                  ? "Custom Images and/or Case Pictures (Required)"
                  : "Upload Images for Sticker (Remaining " +
                    getRemainingImagesForSticker(state.size) +
                    " left)"
                : "Case Pictures (Optional)"}
            </label>
            {state.imageList}
            <div className="area" {...getRootProps()}>
              <input
                {...getInputProps({
                  disabled:
                    parseInt(
                      getRemainingImagesForSticker(state.size).toString()
                    ) === 0,
                })}
                type="file"
                multiple
                accept="image/*"
                id="uploadPicture"
                hidden={true}
                required={props.custom}
              />
              <picture>
                <source srcSet={"images/upload.webp"} />
                <img
                  src={"images/upload.png"}
                  alt=""
                  width="50px"
                  height="50px"
                />
              </picture>

              <span id="upload-text">
                {isDragActive ? "Drop the file(s) to upload." : getFileList()}
              </span>
            </div>
            <div>
              <button
                type="button"
                className="upload-button"
                onClick={clearImages}
              >
                Clear All Images
              </button>
            </div>
          </div>

          {state.productType === 1 ? (
            <div className="productCustomizer">
              <div className="input-area">
                <label htmlFor="caseName" />
                <input
                  name="caseName"
                  type="text"
                  className="form-control"
                  id="caseName"
                  placeholder="Case Name or Case Link (if unsure, upload picture(s) and write unsure)"
                  onChange={handleInput}
                  required
                />
              </div>
              <div className="input-area">
                <label htmlFor="length" />
                <input
                  name="length"
                  type="number"
                  className="form-control"
                  id="length"
                  step="any"
                  placeholder="Approximate Length (inches to the nearest tenth)"
                  onChange={handleInput}
                  required
                />
              </div>
              <div className="input-area">
                <label htmlFor="width" />
                <input
                  name="width"
                  type="number"
                  className="form-control"
                  id="width"
                  step="any"
                  placeholder="Approximate Width (inches to the nearest tenth)"
                  onChange={handleInput}
                  required
                />
              </div>
              <div className="input-area">
                <label htmlFor="height" />
                <input
                  name="height"
                  type="number"
                  className="form-control"
                  id="height"
                  step="any"
                  placeholder="Approximate Height (inches to the nearest tenth)"
                  onChange={handleInput}
                  required
                />
              </div>
            </div>
          ) : null}

          <div className="input-message">
            <label htmlFor="message" />
            <textarea
              name="message"
              className="form-control"
              id="message"
              placeholder="Additional Comments (e.g Please specify your main image and any design requirements. If you want a desired border/background, please state which image to use.)"
              onChange={handleInput}
              required
            />
          </div>

          {props.product.frontPanel ? (
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="checkbox"
                id="frontPanel"
                value="frontPanel"
                onChange={handleInput}
              />
              <label className="form-check-label" htmlFor="frontPanel">
                Front Panel (+$7.00)
              </label>
            </div>
          ) : null}

          {props.product.backPanel ? (
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="checkbox"
                id="backPanel"
                value="backPanel"
                onChange={handleInput}
              />
              <label className="form-check-label" htmlFor="backPanel">
                Back Panel (+$14.00)
              </label>
            </div>
          ) : null}

          <div className="warning-message">
            <p>
              {state.productType === 2 &&
              getRemainingImagesForSticker(state.size) > 0
                ? "Please upload " +
                  getRemainingImagesForSticker(state.size) +
                  " more pictures to submit."
                : null}
            </p>
          </div>

          <div className="add-cart">
            <input
              type="submit"
              value="Add To Cart"
              disabled={
                state.productType === 2 &&
                getRemainingImagesForSticker(state.size) > 0
              }
            />
          </div>
        </form>
      </div>
    </div>
  );
}

export default ProductDetailsRight;

import React from "react";
import "../App.css";
import "./Footer.css";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className="footer">
      <div className="footer-socials">
        <a
          href="https://www.facebook.com/anipcskins/"
          aria-label="Facebook"
          target="_blank"
          rel="noreferrer"
        >
          <i className="fab fa-facebook-f" />
        </a>
        <a
          href="https://www.instagram.com/anipcskins/"
          aria-label="Instagram"
          target="_blank"
          rel="noreferrer"
        >
          <i className="fab fa-instagram" />
        </a>
        <a
          href="https://twitter.com/AniPCskins"
          aria-label="Twitter"
          target="_blank"
          rel="noreferrer"
        >
          <i className="fab fa-twitter" />
        </a>
        <a
          href="https://www.tiktok.com/@anipcskins"
          aria-label="TikTok"
          target="_blank"
          rel="noreferrer"
        >
          <i className="fab fa-tiktok" />
        </a>
        <a
          href="https://www.youtube.com/channel/UCaDadAgNWy76thcXnwkV3gQ"
          aria-label="YouTube"
          target="_blank"
          rel="noreferrer"
        >
          <i className="fab fa-youtube" />
        </a>
      </div>
      <div className="policies">
        <div>
          <Link to="/terms-of-service">Terms of Service</Link>
        </div>
        <div>
          <Link to="/privacy-policy">Privacy Policy</Link>
        </div>
        <div>
          <Link to="/return-policy">Return Policy</Link>
        </div>
        <div>
          <Link to="/faq">FAQ</Link>
        </div>
        <div>
          <Link to="/about">About Us</Link>
        </div>
      </div>
      <div className="footer-email">
        <a href="mailto:support@anipc.net">support@anipc.net</a>
      </div>
      <div className="footer-bottom">
        © 2021 AniPC | Developed by{" "}
        <a href="https://www.joshua-choi.com" target="_blank" rel="noreferrer">
          {" "}
          Joshua Choi{" "}
        </a>
        and{" "}
        <a href="https://www.dlai.one/" target="_blank" rel="noreferrer">
          Desmond Lai
        </a>
      </div>
    </div>
  );
}

export default Footer;

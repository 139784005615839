import React from 'react';
import '../App.css';
import './Hero.css';

function Hero() {
    const pos = document.documentElement;
    pos.addEventListener("mousemove", e => {
        if (window.innerWidth > 600) {
            pos.style.setProperty('--x', e.clientX + "px");
            pos.style.setProperty('--y', e.clientY + "px");
        }
    })

    return (
        <div id="hero">
            <div className="hero-main">
                <div className="hero-background">
                    <picture>
                        <source srcSet={"images/hero-logo.webp"}/>
                        <img className="hero-logo" src={"images/hero-logo.png"} alt="AniPC Logo" width="100%" height="100%"/>
                    </picture>
                    <picture>
                        <source srcSet={"images/hero-logo-small.webp"}/>
                        <img className="hero-logo hero-logo-small" src={"images/hero-logo-small.png"} alt="AniPC Logo" width="100%" height="100%"/>
                    </picture>
                    <h1>AniPC</h1>
                </div>
            </div>
            <div className="down-arrows">
                <span/>
                <span/>
                <span/>
            </div>
        </div>
    )
}

export default Hero
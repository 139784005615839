import React from 'react';
import '../../App.css';
import ProductDetails from '../ProductDetails';
import ItemFooter from '../ItemFooter';

function ProductPage(){
    return (
        <div>
            <ProductDetails/>
            <ItemFooter/>
        </div>
    )
}

export default ProductPage
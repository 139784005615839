import React from 'react';
import '../../App.css';
import OrderComplete from '../OrderComplete';
import Footer from '../Footer';
import MetaTags from "react-meta-tags";

function OrderCompletePage(){
    return(
        <div>
            <MetaTags>
                <title>AniPC - Order Complete</title>
            </MetaTags>
            <OrderComplete/>
            <Footer />
        </div>
    )
}

export default OrderCompletePage
import React, {Component} from 'react';
import '../App.css';
import './Cart.css';
import CartItem from './CartItem';
import {Link} from 'react-router-dom';
import {getCartDetails} from '../Helper'

class Cart extends Component {
    state = {
        loading: true,
        cart: {
            items: []
        },
        cartTotal: 0
    }

    // noinspection DuplicatedCode
    componentDidMount() {
        getCartDetails().then(cartDetails => {
            this.setState(state => ({
                ...state,
                loading: false,
                cart: cartDetails.cart,
                cartTotal: cartDetails.cartTotal
            }))

            this.props.updateCart(this.state.cart.items.length, this.state.cartTotal)
        })
    }

    render() {
        if (this.state.loading) {
            return null
        } else {
            const cartItem = []

            for (let i = 0; i < this.state.cart.items.length; i++) {
                let item = this.state.cart.items[i]
                cartItem.push(<CartItem key={i} cartId={i} item={item}/>)
            }

            return (
                <div className="cart-container">
                    <h1>Cart</h1>
                    <div className={this.state.cart.items.length > 0 ? 'hidden' : 'cart-empty'}>
                        <h2>Your cart is currently empty</h2>
                        <div className="continue-shopping">
                            <Link to="/shop">Continue Shopping</Link>
                        </div>
                    </div>

                    <div className={this.state.cart.items.length > 0 ? 'cart-full' : 'hidden'}>
                        <table>
                            <thead>
                            <tr>
                                <th>Product</th>
                                <th>Subtotal</th>
                            </tr>
                            </thead>
                            <tbody>
                            {cartItem}
                            </tbody>
                        </table>

                        <div className="subtotal">
                            <h2>Subtotal {"$" + this.state.cartTotal.toFixed(2)}</h2>
                            <h3>Shipping, taxes, and discount codes calculated at checkout.</h3>
                            <div className="cart-checkout">
                                <Link to="/checkout">Check Out</Link>
                            </div>
                            <div className="continue-shopping">
                                <Link to="/shop">Continue Shopping</Link>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }
}

export default Cart
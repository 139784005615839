import React, {useCallback, useEffect, useState} from 'react';
import '../App.css';
import './ImageItem.css';
import Cropper from 'react-easy-crop'
import Slider from '@material-ui/core/Slider'
import {getCroppedImg} from "../Helper";

function ImageItem(props) {
    const [crop, setCrop] = useState({x: 0, y: 0})
    const [zoom, setZoom] = useState(1)

    const [state, setState] = useState({
        isCropping: props.productType === 2,
        image: null,
        croppedImage: null,
        cropConfirmed: false,
        croppedArea: {
            x: 0,
            y: 0,
            width: 0,
            height: 0
        },
        croppedAreaPixels: {
            width: 0,
            height: 0,
            x: 0,
            y: 0
        },
        isTransparent: false
    });

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setState({
            ...state,
            croppedArea: croppedArea,
            croppedAreaPixels: croppedAreaPixels
        })
    }, [state])

    const onCrop = () => {
        setState({
            ...state,
            cropConfirmed: state.isCropping,
            isCropping: !state.isCropping
        })
    }

    const confirmCrop = () => {
        setState({
            ...state,
            isCropping: !state.isCropping,
            cropConfirmed: true
        })
    }

    useEffect(() => {
        props.setTransparentImage(props.files, props.index, {
            isTransparent: state.isTransparent
        })

        if (state.cropConfirmed) {
            props.addCroppedImage(props.files, props.index, {
                resizeParameters: {
                    crop: crop,
                    zoom: zoom,
                    croppedArea: state.croppedArea,
                    croppedAreaPixels: state.croppedAreaPixels
                }
            })

            getCroppedImg(state.image, state.croppedAreaPixels).then(croppedImage => {
                setState({
                    ...state,
                    cropConfirmed: false,
                    croppedImage: URL.createObjectURL(croppedImage)
                })
            })
        }

        if (state.image === null) {
            props.addCroppedImage(props.files, props.index, {
                resizeParameters: {
                    crop: crop,
                    zoom: zoom,
                    croppedArea: null,
                    croppedAreaPixels: null,
                }
            })

            setState({
                ...state,
                image: URL.createObjectURL(props.file)
            })
        }
    }, [state, props, crop, zoom])

    const handleInput = (e) => {
        setState({
            ...state,
            isTransparent: e.target.checked
        })
    }

    return (
        <div className="image-item-container">
            <div className="crop-container" hidden={!state.isCropping}>
                {state.isCropping ? <Cropper
                    image={state.image}
                    crop={crop}
                    zoom={zoom}
                    aspect={3 / 3}
                    onCropChange={setCrop}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                /> : null}
            </div>
            <div className="controls" hidden={!state.isCropping}>
                <p>Zoom (Left to Zoom Out, Right to Zoom In)</p>
                <Slider
                    value={zoom}
                    min={1}
                    max={3}
                    step={0.1}
                    aria-labelledby="Zoom"
                    onChange={(e, zoom) => setZoom(zoom)}
                />
                <button type="button" className="upload-button" onClick={confirmCrop}>Confirm Crop</button>
                <div className="form-check form-check-inline">
                    <input className="form-check-input" type="checkbox" name="isTransparent" value={state.isTransparent}
                           onChange={handleInput}/>
                    <label className="form-check-label" htmlFor="isTransparent">Remove Background (Transparent)</label>
                </div>
            </div>
            <div className="image-preview-container" hidden={state.isCropping}>
                {state.croppedImage !== null ?
                    <img id={"image-preview-" + props.index} src={state.croppedImage} alt=""/> :
                    <img id={"image-preview-" + props.index} src={state.image} alt=""/>}
            </div>
            <div className="image-item-information">
                <p>
                    {props.file.name}
                    <span onClick={() => onCrop()} hidden={props.productType !== 2}>
                        <i className="fas fa-crop"/>
                    </span>
                    <span onClick={() => {
                        props.deleteImage(props.files, props.index)
                        state.image = null
                    }}>
                        <i className="far fa-trash-alt"/>
                    </span>
                </p>
            </div>
        </div>
    )
}

export default ImageItem
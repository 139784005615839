import React from 'react';
import '../../App.css';
import Privacy from '../Privacy';
import Footer from '../Footer';
import MetaTags from "react-meta-tags";

function PrivacyPage(){
    return(
        <div>
            <MetaTags>
                <title>AniPC - Privacy Policy</title>
            </MetaTags>
            <Privacy />
            <Footer />
        </div>
    )
}

export default PrivacyPage
import React from 'react';
import '../App.css';
import './Team.css';
import TeamCard from './TeamCard';
import 'bootstrap/dist/css/bootstrap.min.css';

function Team() {
    return (
        <div className="team-container">
            <h1 className="team-header">Meet the Team!</h1>

            <div className="container">
                <div className="row">
                    <div className="col-lg">
                        <TeamCard
                            coverimg="images/team/brandonbanner.png"
                            profileimg="images/team/brandon.png"
                            name="Brandon Lau"
                            position="Founder, Business Team"
                            info="Hi everyone! My name is Brandon Lau, I am currently studying Business at Syracuse University and I am the main founder of AniPC. I enjoy traveling, trying new foods, and I’m a bit of a car enthusiast! In my free time, I like to watch anime and play videogames. Some of my favorite anime include Rakudai Kishi no Cavalry, Kyoukai no Kanata, Golden Time, and many more! Some of the video games I play are League of Legends (sadly), Rainbow Six Siege (also sadly), and I’ve been getting into Resident Evil & Devil May Cry recently. If I could have one custom skin wrap on my PC, I would choose Mai Sakurajima because she is best girl and the embodiment of perfection. :D"
                        />
                    </div>
                    <div className="col-lg">
                        <TeamCard
                            coverimg="images/team/desmondbanner.png"
                            profileimg="images/team/desmond.png"
                            name="Desmond Lai"
                            position="Co-Founder, Website Development Team"
                            info="Hi, my name is Desmond Lai and I am studying Computer Science at the University of Rochester. I play video games such as Forza Horizon 4, Osu!, Teamfight Tactics, and Civilization VI. If I were to go with a skin for my PC I would choose to have a custom skin from the anime Naruto."
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg">
                        <TeamCard
                            coverimg="images/team/jarvisbanner.jpg"
                            profileimg="images/team/jarvis.png"
                            name="Jarvis Ma"
                            position="Co-Founder, Business Team"
                            info="Hello, my name is Jarvis Ma and I attend Binghamton University. In my free time, I like to play video games such as Pokémon, Minecraft, and Rainbow Six Siege. If I could put any PC skin on my case, I would choose the Turtwig evolution line from Pokémon because Pokémon Platinum was the first-ever full playthrough of a game and Turtwig was the starter I went with. "
                        />
                    </div>
                    <div className="col-lg">
                        <TeamCard
                            coverimg="images/team/junlinbanner.jpg"
                            profileimg="images/team/junlin.png"
                            name="Junlin Chen"
                            position="Co-Founder, Customer Service Team"
                            info="Hello, my name is Junlin Chen and I am currently studying Economics. During my free time, I mostly enjoy playing video games and basketball. Some games that I play are Assassin’s Creed, The Witcher 3, FIFA, and 2K. If I had to choose a picture for a custom skin on my PC, it would be a picture of Carmelo Anthony in a Knicks jersey."
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg">
                        <TeamCard
                            coverimg="images/team/anthonybanner.png"
                            profileimg="images/team/anthony.png"
                            name="Anthony Lee"
                            position="Co-Founder, Printing & Cutting Team"
                            info="Hello, my name is Anthony Lee and I am a student at RIT majoring in Computer Science. While I am a gamer I do like to play sports and go out. Some of these things include League of Legends, CS:GO, Rainbow Six Siege, Terraria, Volleyball, and rock climbing. I would get a Guilty Crown themed custom wrap as it was one of my personal favorites."
                        />
                    </div>
                    <div className="col-lg">
                        <TeamCard
                            coverimg="images/team/chowbanner.png"
                            profileimg="images/team/chow.png"
                            name="Christopher Chow"
                            position="Co-Founder, Graphic Design Team"
                            info="Hello, my name is Christopher Chow but I’m one of those guys who everyone calls by their last name. I am majoring in Digital Media, and I love working on designing pc cases, logos, and banners!. In my free time, I like to work out, play handball, watch anime, and play video games. Some of my favorite video game series are Devil May Cry, Monster Hunter, and Shin Megami Tensei. "
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg">
                        <TeamCard
                            coverimg="images/team/junnybanner.png"
                            profileimg="images/team/junny.png"
                            name="Jonathan Chen"
                            position="Co-Founder, Graphic Design Team"
                            info="Hello, my name is Jonathan Chen. I mostly watch anime and play video games. I mostly play video games like Osu! and Rainbow Six Siege. I enjoy designing custom PC in my free time. I would choose to have a Date a Live custom skin on my PC because it is one of my favorite animes. "
                        />
                    </div>
                    <div className="col-lg">
                        <TeamCard
                            coverimg="images/team/brianbanner.jpg"
                            profileimg="images/team/brian.png"
                            name="Brian Pan"
                            position="Co-Founder, Printing & Cutting Team"
                            info="Hi, I’m Brian, I am currently majoring in accounting. In my free time, I enjoy playing single-player video games. Most recently, I’ve played Cyberpunk 2077 and Prey. Additionally, I enjoy playing games in virtual reality, namely Half-Life: Alyx, Beat Saber, and Pistol Whip. If I were to wrap my PC, I would wrap the full case with the Mandalorian."
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Team
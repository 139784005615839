import React from 'react';
import '../../App.css';
import TOS from '../TOS';
import Footer from '../Footer';
import MetaTags from "react-meta-tags";

function TOSPage(){
    return(
        <div>
            <MetaTags>
                <title>AniPC - Terms of Service</title>
            </MetaTags>
            <TOS />
            <Footer />
        </div>
    )
}

export default TOSPage
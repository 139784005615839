import React, { Component } from "react";
import "../App.css";
import "./Products.css";
import ProductCard from "./ProductCard";
import "bootstrap/dist/css/bootstrap.min.css";
import { withRouter } from "react-router-dom";

const queryString = require("query-string");

class Products extends Component {
  state = {
    loading: true,
    products: [],
    category: null,
  };

  componentDidMount() {
    let query = queryString.parse(this.props.location.search).id;

    if (query !== undefined) {
      fetch("/api/products?categoryId=" + query).then(async (res) => {
        const json = await res.json();

        // noinspection JSUnresolvedVariable
        if (json.success) {
          // noinspection JSUnresolvedVariable
          this.setState({
            loading: false,
            products: json.products,
            category: json.category,
          });
        }
      });
    } else {
      fetch("/api/products?categoryId=undefined").then(async (res) => {
        const json = await res.json();

        // noinspection JSUnresolvedVariable
        if (json.success) {
          // noinspection JSUnresolvedVariable
          this.setState({
            loading: false,
            products: json.products,
            category: json.category,
          });
        }
      });
    }
  }

  render() {
    if (this.state.loading) {
      return null;
    } else {
      /* Solution: https://stackoverflow.com/questions/42391499/react-render-new-row-every-4th-column */

      const rows = [...Array(Math.ceil(this.state.products.length / 4))];
      const productRows = rows.map((row, idx) =>
        this.state.products.slice(idx * 4, idx * 4 + 4)
      );
      const content = productRows.map((row, idx) => (
        <div className="row" key={idx}>
          {row.map((product) => (
            <ProductCard
              key={product.id}
              id={product.id}
              category={product.categoryName}
              productType={product.productTypeId}
              title={product.name}
              imagePath={"../" + product.imageFolderPath + "/1"}
              imageExtension={".png"}
              new={product.new}
              sale={product.sale}
              smallprice={product.priceSmall}
              largeprice={product.priceLarge}
              smallsale={(
                product.priceSmall *
                (1 - product.salePercent / 100)
              ).toFixed(2)}
              largesale={(
                product.priceLarge *
                (1 - product.salePercent / 100)
              ).toFixed(2)}
            />
          ))}
        </div>
      ));

      let productBannerStyle = {
        backgroundImage: `url('/images/shop/allproducts/banner.png')`,
      };

      if (this.state.category !== undefined) {
        productBannerStyle = {
          backgroundImage: `url(${this.state.category.imagePath}/banner.png)`,
        };
      }

      let useCover =
        this.state.category === undefined ||
        this.state.category.id === 4 ||
        this.state.category.id === 5;

      return (
        <div className="products-container">
          <div
            className={useCover ? "products-banner-alt" : "products-banner"}
            style={productBannerStyle}
          ></div>
          <div className="products-content container-fluid">{content}</div>
        </div>
      );
    }
  }
}

export default withRouter(Products);

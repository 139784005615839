import React, {useEffect, useState} from 'react';
import '../../App.css';
import CheckOut from '../CheckOut';
import Footer from "../Footer";
import MetaTags from "react-meta-tags";

function CheckOutPage(){
    const [state, setState] = useState({
        loading: true
    });

    useEffect(() => {
        fetch("/api/paypal").then(async res => {
            const json = await res.json()
            const script = document.createElement('script')
            script.src = "https://www.paypal.com/sdk/js?client-id=" + json.id;
            document.body.appendChild(script);

            script.onload = () => {
                setState({
                    loading: false
                })
            };
        })
    }, [])

    return state.loading ? null : (
        <div>
            <MetaTags>
                <title>AniPC - Checkout</title>
            </MetaTags>
            <CheckOut/>
            <Footer/>
        </div>
    )
}

export default CheckOutPage
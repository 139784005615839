import React, { useEffect, useState } from "react";
import "../App.css";
import "./ProductDetails.css";
import ProductDetailsLeft from "./ProductDetailsLeft";
import ProductDetailsMiddle from "./ProductDetailsMiddle";
import ProductDetailsRight from "./ProductDetailsRight";
import { useLocation, useHistory, Redirect } from "react-router-dom";
import MetaTags from "react-meta-tags";

const queryString = require("query-string");

function ProductDetails() {
  const [state, setState] = useState({
    loading: true,
    redirect: false,
    product: [],
    custom: false,
  });
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    let queryStringValues = queryString.parse(location.search);

    if (queryStringValues.id !== undefined) {
      fetch("/api/item?productId=" + queryStringValues.id).then(async (res) => {
        const json = await res.json();

        // noinspection JSUnresolvedVariable
        if (json.success) {
          // noinspection JSUnresolvedVariable
          let product = json.item;

          setState((state) => ({
            ...state,
            product: product,
            loading: false,
            custom: product.categoryId === 5,
          }));
        }
      });
    } else {
      setState((state) => ({
        ...state,
        loading: false,
        redirect: true,
      }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return state.loading ? null : state.redirect ? (
    <Redirect to={"/"} />
  ) : (
    <div className="product-details-container">
      <MetaTags>
        <title>AniPC - {state.product.name} Skin</title>
      </MetaTags>
      <div className="buy">
        <ProductDetailsLeft
          front={
            state.product.numberOfImages > 0
              ? state.product.imageFolderPath + "/1.png"
              : null
          }
          side={
            state.product.numberOfImages >= 2
              ? state.product.imageFolderPath + "/2.png"
              : null
          }
          back={
            state.product.numberOfImages === 3
              ? state.product.imageFolderPath + "/3.png"
              : null
          }
        />
        <ProductDetailsMiddle
          front={
            state.product.numberOfImages > 0
              ? state.product.imageFolderPath + "/1.png"
              : null
          }
          side={
            state.product.numberOfImages >= 2
              ? state.product.imageFolderPath + "/2.png"
              : null
          }
          back={
            state.product.numberOfImages === 3
              ? state.product.imageFolderPath + "/3.png"
              : null
          }
        />
      </div>
      <ProductDetailsRight
        product={state.product}
        size={queryString.parse(location.search).size}
        custom={state.custom}
      />
      <div className="exit">
        <button onClick={history.goBack}>
          <i className="fas fa-times" />
        </button>
      </div>
    </div>
  );
}

export default ProductDetails;

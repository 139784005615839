import React from 'react';
import '../App.css';
import './ProductDetails.css';
import 'bootstrap/dist/css/bootstrap.min.css';

function ProductDetailsMiddle(props) {
    return (
        <div className="details-middle-container">
            <div className="container-fluid">
                <div className="row">
                    <div id="front" className="front col-md">
                        <img src={props.front} alt=""/>
                    </div>
                </div>
                <div id="side" className="row">
                    <div className="side col-md">
                        <img src={props.side} alt=""/>
                    </div>
                </div>
                <div id="back" className="row">
                    <div className="back col-md">
                        <img src={props.back} alt=""/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProductDetailsMiddle
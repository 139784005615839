import React from 'react';
import '../App.css';
import './CatalogCard.css';
import {Link} from 'react-router-dom';

function CatalogCard(props) {
    return (
        <div className="col-md">
            <div className="catalog-card-container">
                <Link to={"/collection?id=" + props.id}>
                    <picture>
                        <source srcSet={props.imagePath + ".webp"}/>
                        <img src={props.imagePath + props.imageExtension} alt={"Image of " + props.title}/>
                    </picture>
                </Link>
            </div>
            <div className="catalog-card-name-container">
                <p>{props.title}</p>
            </div>
        </div>
    )
}

export default CatalogCard